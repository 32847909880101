export default {
	general: {
		save: 'Tallenna',
		cancel: 'Peruuta',
		yes: 'Kyllä',
		no: 'Ei',
		goBack: 'Palaa takaisin',
		close: 'Sulje',
		purchase: 'Osta',
		valid: 'Voimassa',
		notValid: 'Ei voimassa',
		paid: 'Maksettu',
		notPaid: 'Ei maksettu',
		product: 'Tuote',
		price: 'Hinta',
		changeCookieSettings: 'Muuta evästeasetuksiasi',
		latestAssociationInvoiceNotValid: 'Sinun tulee maksaa viimeisin Suomen Ilmailuliiton jäsenlasku suorittaaksesi tämän toiminnon.',
		noValidPriceList: 'Toiminnon suorittaminen ei onnistunut, koska hinnastoa ei ole julkaistu kuluvalle vuodelle.',
	},
	routes: {
		error404: {
			title: 'Sivua ei löytynyt',
		},
		membershipCard: {
			title: 'Kortti',
		},
		myAccount: {
			title: 'Omat tiedot',
			headline: 'Omat tiedot',
		},
		benefitContainer: {
			title: 'Jäsenedut',
			headline: 'Jäsenedut',
		},
		benefit: {
			title: 'Jäsenetu',
			headline: 'Jäsenetu',
		},
		eventContainer: {
			title: 'Tapahtumat',
			headline: 'Tapahtumat',
		},
		event: {
			title: 'Tapahtuma',
			headline: 'Tapahtuma',
		},
		newsContainer: {
			title: 'Uutiset',
			headline: 'Uutiset',
		},
		news: {
			title: 'Uutinen',
			headline: 'Uutinen',
		},
		pageContainer: {
			title: 'Sivut',
			headline: 'Sivut',
		},
		page: {
			title: 'Sivu',
			headline: 'Sivu',
		},
		magazineContainer: {
			title: 'Lehti',
			headline: 'Jäsenlehti',
		},
		licenseContainer: {
			title: 'Lisenssit',
			headline: 'Lisenssit',
		},
		license: {
			title: 'Lisenssi',
			headline: 'Lisenssi',
		},
		invoiceContainer: {
			title: 'Laskut',
			headline: 'Laskut',
		},
		invoice: {
			title: 'Lasku',
			headline: 'Lasku',
		},
		settings: {
			title: 'Asetukset',
			headline: 'Asetukset',
		},
	},
	cookieTypes: {
		necessary: 'Välttämättömät',
		statistics: 'Tilastointi',
		marketing: 'Markkinointi',
	},
}
