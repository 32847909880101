<i18n>
{
  "en": {
		"noItemsMessage": "No magazines to show."
	},
  "fi": {
		"noItemsMessage": "Jäsenlehtiä ei löytynyt."
	}
}
</i18n>

<template>
	<v-container
		class="container--narrow"
	>
		<v-row
			v-if="items.length"
		>
			<v-col
				v-for="item in items"
				:key="item.id"
				cols="6"
				sm="4"
			>
				<v-card
					:href="item.file_pdf"
					target="_blank"
				>
					<v-img
						v-if="item.image"
						:src="item.image.url"
					/>
					<v-card-text class="text-center">
						{{ item.title }}
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-alert
			v-else
			type="info"
		>
			{{ $i18n.t('noItemsMessage') }}
		</v-alert>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MagazineContainer',
	computed: {
		...mapState({
			items: state => state.magazines,
		}),
	},
	mounted () {
		this.$api.Magazines.doRequest()
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'page', params: { pagename: item.name } })
		},
	},
}
</script>
