<template>
	<div
		v-if="Object.keys(user).length && Object.keys(license).length && styles && Object.keys(styles).length"
		:class="[
			'licenseCard',
			rotated === true ? 'licenseCard--rotated' : null,
			license._is_parachute_license === true ? 'licenseCard--parachute' : null,
			license._is_pilot_license === true ? 'licenseCard--pilot' : null,
			license._is_drone_remote_controller_license === true ? 'licenseCard--droneRemoteController' : null,
			license._is_drone_degree_officer_license === true ? 'licenseCard--droneDegreeOfficer' : null,
			devMode === true ? 'licenseCard--dev' : null,
		]"
		@click="rotated = !rotated"
	>
		<div class="licenseCard__wrap">
			<div
				class="licenseCard__side licenseCard__side--front"
				:style="styles.front"
			>
				<span class="licenseCard__field licenseCard__field--name">
					{{ user._full_name }}
				</span>
				<span class="licenseCard__field licenseCard__field--dateOfBirth">
					{{ user._birthdate_formatted }}
				</span>
				<span
					v-if="license._is_parachute_license === true"
					class="licenseCard__field licenseCard__field--certificate"
				>
					{{ license._license_type }}
				</span>
				<span class="licenseCard__field licenseCard__field--licenseNumber">
					{{ license.c_lisenssi_lupanumero }}
				</span>
				<span
					v-if="license._is_parachute_license === true"
					class="licenseCard__field licenseCard__field--expires"
				>
					{{ license._enddate_formatted }}
				</span>
				<span
					v-if="license._is_pilot_license === true || license._is_drone_remote_controller_license === true || license._is_drone_degree_officer_license === true"
					class="licenseCard__field licenseCard__field--dateOfIssue"
				>
					{{ license._startdate_formatted }}
				</span>
				<span class="licenseCard__field licenseCard__field--ratings">
					{{ license._ratings_card }}
				</span>
			</div>
			<div
				class="licenseCard__side licenseCard__side--back"
				:style="styles.back"
			/>
		</div>
	</div>
</template>

<script>

export default {
	name: 'LicenseCard',
	props: {
		user: {
			type: [Object],
			required: false,
			default () {
				return {}
			},
		},
		license: {
			type: [Object],
			required: false,
			default () {
				return {}
			},
		},
	},
	data: () => ({
		devMode: process.env.NODE_ENV === 'development',
		rotated: false,
		licenseCardImages: {
			a: {
				front: require('@/assets/license-cards/license-card-a.jpg'),
				back: require('@/assets/license-cards/license-card-parachute-back.jpg'),
			},
			b: {
				front: require('@/assets/license-cards/license-card-b.jpg'),
				back: require('@/assets/license-cards/license-card-parachute-back.jpg'),
			},
			c: {
				front: require('@/assets/license-cards/license-card-c.jpg'),
				back: require('@/assets/license-cards/license-card-parachute-back.jpg'),
			},
			d: {
				front: require('@/assets/license-cards/license-card-d.jpg'),
				back: require('@/assets/license-cards/license-card-parachute-back.jpg'),
			},
			pilot: {
				front: require('@/assets/license-cards/license-card-pilot.jpg'),
				back: require('@/assets/license-cards/license-card-pilot-back.jpg'),
			},
			drone_remote_controller: {
				front: require('@/assets/license-cards/license-card-drone-remote-controller-front.jpg'),
				back: require('@/assets/license-cards/license-card-drone-remote-controller-back.jpg'),
			},
			drone_degree_officer: {
				front: require('@/assets/license-cards/license-card-drone-degree-officer-front.jpg'),
				back: require('@/assets/license-cards/license-card-drone-degree-officer-back.jpg'),
			},
		},
	}),
	computed: {
		styles () {
			if (
				!this.license._license_type ||
				typeof this.licenseCardImages[this.license._license_type] == 'undefined'
			) return {}

			return {
				front: {
					backgroundImage: 'url(' + this.licenseCardImages[this.license._license_type].front + ')',
				},
				back: {
					backgroundImage: 'url(' + this.licenseCardImages[this.license._license_type].back + ')',
				},
			}
		},
	},
}

</script>

<style lang="scss" scoped>

	.licenseCard {
		cursor: pointer;
		display: block;
		position: relative;
		width: 100%;
		padding-top: 63.5%;
		font-family: 'Arial', 'sans-serif';
		color: #000;
		perspective: 1000px;
		color-adjust: exact;
		-webkit-print-color-adjust: exact;

		@media print {
			visibility: visible;
			position: relative;
			padding: 0;

			* {
				position: relative;
				visibility: visible;
			}
		}

		&__wrap,
		&__side {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&__wrap {
			transition: transform 800ms ease;
			transform-style: preserve-3d;

			.licenseCard--rotated & {
				transform: rotateY(180deg);
			}

			@media print {
				transform: rotateY(0) !important;
			}
		}

		&__side {
			background-size: cover;
			backface-visibility: hidden;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;

			@media print {
				position: relative;
				width: 85.6mm;
				height: 54.36mm;
				border-radius: 3mm;
			}

			&--back {
				transform: rotateY(180deg);

				@media print {
					margin-top: 10mm;
					transform: rotateY(0);
				}
			}
		}

		&__field {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			position: absolute;
			width: 56.8%;
			height: 7.8%;
			font-size: 2.2vw;
			white-space: nowrap;
			overflow: hidden;

			@media (min-width: 700px) {
				font-size: 15.4px;
			}

			@media print {
				font-size: 6.5pt;
			}

			// Highlight fields in dev mode
			.licenseCard--dev & {
				background: rgba(131, 0, 0, .2);

				@media print {
					background: transparent;
				}
			}

			&--name {
				top: 21.3%;
				left: 13.1%;
				width: 47.6%;

				.licenseCard--pilot & {
					left: 14.1%;
				}

				.licenseCard--droneRemoteController &,
				.licenseCard--droneDegreeOfficer & {
					left: 12.5%;
					width: 46.5%;
				}

				.licenseCard--droneRemoteController & {
					top: 17.2%;
				}

				.licenseCard--droneDegreeOfficer & {
					top: 17.7%;
				}
			}

			&--dateOfBirth {
				top: 30.4%;
				left: 23.3%;
				width: 37.4%;

				.licenseCard--pilot & {
					left: 24.3%;
				}

				.licenseCard--droneRemoteController &,
				.licenseCard--droneDegreeOfficer & {
					left: 22%;
					width: 37%;
				}

				.licenseCard--droneRemoteController & {
					top: 29.2%;
				}

				.licenseCard--droneDegreeOfficer & {
					top: 30%;
				}
			}

			&--certificate {
				top: 39.6%;
				left: 39.2%;
				width: 21.5%;
				text-transform: uppercase;

				.licenseCard--pilot & {
					display: none;
				}
			}

			&--licenseNumber {
				top: 48.8%;
				left: 19.3%;
				width: 14.2%;

				.licenseCard--pilot & {
					top: 39.6%;
					left: 20.5%;
					width: 40.9%;
				}

				.licenseCard--droneRemoteController &,
				.licenseCard--droneDegreeOfficer & {
					left: 33.5%;
					width: 25.2%;
				}

				.licenseCard--droneRemoteController & {
					top: 64.2%;
				}

				.licenseCard--droneDegreeOfficer & {
					top: 64.5%;
				}
			}

			&--expires {
				top: 48.8%;
				left: 46.5%;
				width: 14.2%;
			}

			&--dateOfIssue {
				top: 48.8%;
				left: 23.8%;
				width: 37.5%;

				.licenseCard--droneRemoteController &,
				.licenseCard--droneDegreeOfficer & {
					left: 24.5%;
					width: 34.5%;
				}

				.licenseCard--droneRemoteController & {
					top: 52.5%;
				}

				.licenseCard--droneDegreeOfficer & {
					top: 52.9%;
				}
			}

			&--ratings {
				top: 65.5%;
				left: 4.8%;
				width: 55.9%;

				.licenseCard--pilot & {
					left: 5.8%;
				}

				.licenseCard--droneRemoteController &,
				.licenseCard--droneDegreeOfficer & {
					left: 12.5%;
					width: 46.5%;
				}

				.licenseCard--droneRemoteController & {
					top: 41%;
				}

				.licenseCard--droneDegreeOfficer & {
					top: 41.4%;
				}
			}
		}
	}

</style>
