var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.user).length && Object.keys(_vm.license).length && _vm.styles && Object.keys(_vm.styles).length)?_c('div',{class:[
		'licenseCard',
		_vm.rotated === true ? 'licenseCard--rotated' : null,
		_vm.license._is_parachute_license === true ? 'licenseCard--parachute' : null,
		_vm.license._is_pilot_license === true ? 'licenseCard--pilot' : null,
		_vm.license._is_drone_remote_controller_license === true ? 'licenseCard--droneRemoteController' : null,
		_vm.license._is_drone_degree_officer_license === true ? 'licenseCard--droneDegreeOfficer' : null,
		_vm.devMode === true ? 'licenseCard--dev' : null ],on:{"click":function($event){_vm.rotated = !_vm.rotated}}},[_c('div',{staticClass:"licenseCard__wrap"},[_c('div',{staticClass:"licenseCard__side licenseCard__side--front",style:(_vm.styles.front)},[_c('span',{staticClass:"licenseCard__field licenseCard__field--name"},[_vm._v(" "+_vm._s(_vm.user._full_name)+" ")]),_c('span',{staticClass:"licenseCard__field licenseCard__field--dateOfBirth"},[_vm._v(" "+_vm._s(_vm.user._birthdate_formatted)+" ")]),(_vm.license._is_parachute_license === true)?_c('span',{staticClass:"licenseCard__field licenseCard__field--certificate"},[_vm._v(" "+_vm._s(_vm.license._license_type)+" ")]):_vm._e(),_c('span',{staticClass:"licenseCard__field licenseCard__field--licenseNumber"},[_vm._v(" "+_vm._s(_vm.license.c_lisenssi_lupanumero)+" ")]),(_vm.license._is_parachute_license === true)?_c('span',{staticClass:"licenseCard__field licenseCard__field--expires"},[_vm._v(" "+_vm._s(_vm.license._enddate_formatted)+" ")]):_vm._e(),(_vm.license._is_pilot_license === true || _vm.license._is_drone_remote_controller_license === true || _vm.license._is_drone_degree_officer_license === true)?_c('span',{staticClass:"licenseCard__field licenseCard__field--dateOfIssue"},[_vm._v(" "+_vm._s(_vm.license._startdate_formatted)+" ")]):_vm._e(),_c('span',{staticClass:"licenseCard__field licenseCard__field--ratings"},[_vm._v(" "+_vm._s(_vm.license._ratings_card)+" ")])]),_c('div',{staticClass:"licenseCard__side licenseCard__side--back",style:(_vm.styles.back)})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }