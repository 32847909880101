import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './i18n'
import router from './router'
import store from './store'
import api from './plugins/api'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// Global components
import Spinner from '@/components/Spinner'
import FilterableList from '@/components/FilterableList'
import SchemaToForm from '@/components/SchemaToForm'
import ContentBlocks from '@/components/ContentBlocks'
import CookieContent from '@/components/CookieContent'
import LicenseList from '@/components/LicenseList'

Vue.component('Spinner', Spinner)
Vue.component('FilterableList', FilterableList)
Vue.component('SchemaToForm', SchemaToForm)
Vue.component('ContentBlocks', ContentBlocks)
Vue.component('CookieContent', CookieContent)
Vue.component('LicenseList', LicenseList)

// Use API plugin
Vue.use(api)

// Use Gtag plugin
Vue.use(VueGtag, {
	config: {
		id: null,
		anonymize_ip: true,
	},
	enabled: false, // Will be enabled after cookie consent
}, router)

Vue.config.productionTip = false

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
