<i18n>
{
	"en": {
		"buttons": {
			"editYourDetails": "Edit your details",
			"downloadPDF": "Download PDF"
		},
		"insurances": {
			"title": "Insurances"
		},
		"licenses": {
			"title": "Licenses",
			"renewButtonText": "Renew license",
			"showAllButtonText": "Show all licenses",
			"renewInfoText": "You can renew your A, B, C, or D license if you have had at least 10 jumps in the last 12 months and meet the health requirements.",
			"linkButtons": {
				"showRequirements": "Requirements",
				"showPromotions": "Promotions and trainer qualifications"
			},
			"licenseToBeRenewed": "License that will be renewed",
			"expirationDateAfterRenewal": "Expiration date after renewal",
			"renewLicenseLoadingText": "Renewing license"
		},
		"fai": {
			"title": "FAI license",
			"noLicenseText": "Valid FAI license was not found.",
			"purchaseButtonText": "Purchase FAI license",
			"checkLicenseButtonText": "Check your FAI license",
			"endButtonText": "End FAI license",
			"loadingSaveMembershipText": "Creating FAI license",
			"loadingEndMembershipText": "Ending FAI license",
			"endMembershipInfoText": "Are you sure you want to end your FAI license?"
		},
		"athleteAgreement": {
			"title": "Athlete agreement",
			"noAgreementText": "You have no valid athlete agreement.",
			"acquire": "Create athlete agreement",
			"dialog": {
				"infoText": "Please read terms before creating a new athlete agreement.",
				"linkText": "Read terms"
			}
		}
	},
	"fi": {
		"buttons": {
			"editYourDetails": "Muokkaa tietojasi",
			"downloadPDF": "Lataa PDF"
		},
		"insurances": {
			"title": "Vakuutukset"
		},
		"licenses": {
			"title": "Lisenssit",
			"renewButtonText": "Uudista lisenssi",
			"showAllButtonText": "Näytä kaikki lisenssit",
			"renewInfoText": "Voit uudistaa lisenssisi A, B, C tai D, jos sinulla on vähintään 10 hyppyä viimeisen 12 kk aikana ja täytät terveydentilavaatimukset.",
			"linkButtons": {
				"showRequirements": "Vaatimukset",
				"showPromotions": "Korotukset ja kouluttajakelpoisuudet"
			},
			"licenseToBeRenewed": "Uudistettava lisenssi",
			"expirationDateAfterRenewal": "Päättymispäivä uudistamisen jälkeen",
			"renewLicenseLoadingText": "Uudistetaan lisenssiä"
		},
		"fai": {
			"title": "FAI-lisenssi",
			"noLicenseText": "Sinulla ei ole voimassa olevaa FAI-lisenssiä.",
			"checkLicenseButtonText": "Tarkista FAI-lisenssi",
			"purchaseButtonText": "Osta FAI-lisenssi",
			"endButtonText": "Päätä FAI-lisenssi",
			"loadingSaveMembershipText": "Luodaan FAI-lisenssiä",
			"loadingEndMembershipText": "Päätetään FAI-lisenssi",
			"endMembershipInfoText": "Haluatko varmasti päättää FAI-lisenssisi?"
		},
		"athleteAgreement": {
			"title": "Urheilijasopimus",
			"noAgreementText": "Sinulla ei ole voimassa olevaa urheilijasopimusta.",
			"acquire": "Tee urheilijasopimus",
			"dialog": {
				"infoText": "Tutustu urheilijasopimuksen ehtoihin ennen sopimuksen luontia.",
				"linkText": "Lue sopimusehdot"
			}
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card class="membershipCard">
				<v-img
					v-if="config._membership_card_image"
					class="membershipCard__image align-end"
					aspect-ratio="1.628"
					:src="config._membership_card_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-card-title class="membershipCard__title headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user._membership_titles && user._membership_titles.length"
						class="membershipCard__subtitle"
					>
						<ul class="no-list">
							<li
								v-for="item in user._membership_titles"
								:key="item.id"
								class="d-block"
							>
								{{ item.title }}
							</li>
						</ul>
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle v-if="user._membership_titles && user._membership_titles.length">
						<ul class="no-list">
							<li
								v-for="item in user._membership_titles"
								:key="item.id"
								class="d-block"
							>
								{{ item.title }}
							</li>
						</ul>
					</v-card-subtitle>
				</template>

				<!-- Validity text -->
				<p
					v-if="user._membership_validity_text && user._membership_validity_text.length"
					class="membershipCard__validityText"
				>
					{{ user._membership_validity_text }}
				</p>

				<!-- Suomen ilmailulisenssi -->
				<p
					class="membershipCard__suomenIlmailulisenssi"
				>
					Suomen ilmailulisenssi
				</p>

				<!-- FAI LOGO -->
				<v-img
					class="membershipCard__logo membershipCard__logo--fai"
					v-if="user._fai_license && user._fai_license._is_valid === true"
					width="40px"
					height="60px"
					contain
					src="@/assets/fai-logo.svg"
				/>

				<!-- SIL LOGO -->
				<v-img
					class="membershipCard__logo membershipCard__logo--sil"
					width="50px"
					height="46px"
					contain
					src="@/assets/logo.svg"
				/>

				<v-card-text v-if="user._details && user._details.length">
					<FilterableList
						:items="user._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'myAccount' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('buttons.editYourDetails') }}
					</v-btn>
					<v-btn
						color="primary"
						text
						@click="pdfDialogOpen = true"
					>
						<v-icon left>
							mdi-file-pdf-box
						</v-icon>
						{{ $i18n.t('buttons.downloadPDF') }}
					</v-btn>
				</v-card-actions>
			</v-card>

			<!-- PDF dialog -->
			<v-dialog
				v-model="pdfDialogOpen"
				max-width="400"
				scrollable
			>
				<v-card>
					<v-card-title>
						{{ $i18n.t('buttons.downloadPDF') }}
					</v-card-title>
					<v-card-text>
						<FilterableList
							:items="pdfDownloadLinks"
							title-src="list_title"
							icon-src="icon"
							:enable-search="false"
						/>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn
							text
							color="primary"
							@click="pdfDialogOpen = false"
						>
							{{ $i18n.t('general.close') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>

		<!-- Printable membership card -->
		<div
			class="membershipCardPrintable"
			:style="{
				backgroundImage: config._membership_card_image.url ? 'url(' + config._membership_card_image.url + ')' : null,
			}"
		>
			<v-card-title class="membershipCardPrintable__title">
				{{ user._full_name }}
			</v-card-title>
			<v-card-subtitle
				v-if="user._membership_titles && user._membership_titles.length"
				class="membershipCardPrintable__subtitle"
			>
				<ul class="no-list">
					<li
						v-for="item in user._membership_titles"
						:key="item.id"
						class="d-block"
					>
						{{ item.title }}
					</li>
				</ul>
			</v-card-subtitle>

			<!-- Validity text -->
			<p
				v-if="user._membership_validity_text && user._membership_validity_text.length"
				class="membershipCardPrintable__validityText"
			>
				{{ user._membership_validity_text }}
			</p>

			<!-- FAI LOGO -->
			<img
				class="membershipCardPrintable__logo membershipCardPrintable__logo--fai"
				v-if="user._fai_license && user._fai_license._is_valid === true"
				contain
				src="@/assets/fai-logo.svg"
			>

			<!-- SIL LOGO -->
			<img
				class="membershipCardPrintable__logo membershipCardPrintable__logo--sil"
				contain
				src="@/assets/logo.svg"
			>
		</div>

		<!-- Insurances -->
		<v-container
			v-if="user._is_beta_tester === true"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('insurances.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="user._insurances"
						title-src="list_title"
						summary-src="list_summary"
						icon-src="icon"
						:enable-search="false"
						:enable-click="false"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Licenses -->
		<v-container
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('licenses.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<LicenseList
						:items="user._active_licenses"
					/>
				</v-card-text>
				<template v-if="user.lisenssit.length">
					<v-divider />
					<v-card-actions>
						<v-btn
							color="primary"
							text
							@click="renewLicenseDialogOpen = true"
						>
							<v-icon left>
								mdi-star
							</v-icon>
							{{ $i18n.t('licenses.renewButtonText') }}
						</v-btn>
						<v-btn
							:to="{ name: 'licenseContainer' }"
							color="primary"
							text
						>
							<v-icon left>
								mdi-license
							</v-icon>
							{{ $i18n.t('licenses.showAllButtonText') }}
						</v-btn>
					</v-card-actions>
				</template>

				<!-- Renew license dialog -->
				<v-dialog
					v-model="renewLicenseDialogOpen"
					:max-width="user._renewable_parachute_license && user._renewable_parachute_license.item === null ? 300 : 500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('licenses.renewButtonText') }}
						</v-card-title>
						<v-card-text>
							<v-alert
								v-if="user._renewable_parachute_license.enabled === false"
								type="error"
							>
								{{ user._renewable_parachute_license.disabledReason }}
							</v-alert>
							<template v-else>
								<Spinner
									v-if="renewLicenseSaveLoading === true"
									:text="renewLicenseLoadingText"
								/>
								<template v-else>
									<FilterableList
										:enable-search="false"
										:enable-click="false"
										:items="[
											{
												icon: user._renewable_parachute_license.item._icon,
												subtitle: $i18n.t('licenses.licenseToBeRenewed'),
												title: user._renewable_parachute_license.item.ratings,
											},
											{
												icon: 'mdi-calendar',
												subtitle: $i18n.t('licenses.expirationDateAfterRenewal'),
												title: user._renewable_parachute_license.newEndDate_formatted,
											}
										]"
									/>
									<v-alert
										type="info"
										class="mt-3"
									>
										{{ $i18n.t('licenses.renewInfoText') }}
									</v-alert>
									<FilterableList
										:enable-click="false"
										:enable-search="false"
										:items="[
											{
												icon: 'mdi-open-in-new',
												title: $i18n.t('licenses.linkButtons.showRequirements'),
												redirect_url: 'http://laskuvarjotoimikunta.ilmailuliitto.fi/toiminnalliset-ohjeet/',
											},
											{
												icon: 'mdi-open-in-new',
												title: $i18n.t('licenses.linkButtons.showPromotions'),
												redirect_url: 'https://www.ilmailuliitto.fi/laji/laskuvarjourheilu/lisenssit-ja-kelpoisuudet/',
											},
										]"
									/>
									<SchemaToForm
										class="mt-6"
										ref="renewLicenseForm"
										v-model="renewLicenseFormData"
										:schema="config.forms.licenseRenew"
										:disabled="renewLicenseSaveLoading"
										@valid="renewLicenseFormValid = true"
										@invalid="renewLicenseFormValid = false"
									/>
								</template>
							</template>
						</v-card-text>
						<v-card-actions>
							<template v-if="user._renewable_parachute_license && user._renewable_parachute_license.item === null">
								<v-spacer />
								<v-btn
									text
									@click="renewLicenseDialogOpen = false"
								>
									OK
								</v-btn>
							</template>
							<template v-else>
								<v-btn
									text
									:disabled="renewLicenseSaveLoading === true"
									@click="renewLicenseDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									text
									color="primary"
									:disabled="renewLicenseFormValid === false || renewLicenseSaveLoading === true"
									@click="renewLicense"
								>
									{{ $i18n.t('licenses.renewButtonText') }}
								</v-btn>
							</template>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-card>
		</v-container>

		<!-- FAI license -->
		<v-container
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('fai.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						v-if="user._fai_license"
						:items="[user._fai_license]"
						title-src="_list_title"
						summary-src="_list_summary"
						icon-src="_icon"
						icon-color-src="_icon_color"
						:enable-search="false"
						:multiline="true"
						@itemClick="faiDetailsDialogOpen = true"
					>
						<template #meta="slotProps">
							<v-chip
								v-if="slotProps.item._status && Object.keys(slotProps.item._status).length"
								:color="slotProps.item._status.color"
								outlined
								label
								@click.stop="slotProps.item._invoice && Object.keys(slotProps.item._invoice).length ? $router.push({ name: 'invoice', params: { pagename: slotProps.item._invoice.id } }) : null"
							>
								<v-icon
									v-if="slotProps.item._status.icon"
									left
								>
									{{ slotProps.item._status.icon }}
								</v-icon>

								{{ slotProps.item._status.text }}
							</v-chip>
						</template>
					</FilterableList>
					<v-alert
						v-else
						type="info"
						class="mb-0"
					>
						{{ $i18n.t('fai.noLicenseText') }}
					</v-alert>
				</v-card-text>
				<template v-if="!user._fai_license">
					<v-divider />
					<v-card-actions>
						<v-btn
							v-if="!user._fai_license"
							color="primary"
							text
							@click="faiPurchaseDialogOpen = true"
						>
							<v-icon left>
								mdi-cart
							</v-icon>
							{{ $i18n.t('fai.purchaseButtonText') }}
						</v-btn>
					</v-card-actions>
				</template>

				<!-- FAI license details dialog -->
				<v-dialog
					v-if="user._fai_license"
					v-model="faiDetailsDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('fai.title') }}
						</v-card-title>
						<v-card-text>
							<Spinner
								v-if="faiSaveLoading === true"
								:text="faiSaveLoadingText"
							/>
							<FilterableList
								v-else-if="user._fai_license"
								:items="user._fai_license._details"
								subtitle-src="label"
								title-src="value"
								icon-src="icon"
								:enable-click="false"
								:enable-search="false"
								:multiline="true"
							/>
							<v-btn
								v-if="config.fai_license_check_link"
								color="primary"
								class="mt-3 mr-3"
								:href="config.fai_license_check_link"
								target="_blank"
							>
								<v-icon left>
									mdi-open-in-new
								</v-icon>
								{{ $i18n.t('fai.checkLicenseButtonText') }}
							</v-btn>
							<v-btn
								v-if="user._fai_license._is_endable === true"
								text
								class="mt-3"
								color="primary"
								@click="faiEndDialogOpen = true"
							>
								<v-icon left>
									mdi-close
								</v-icon>
								{{ $i18n.t('fai.endButtonText') }}
							</v-btn>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								text
								color="primary"
								:disabled="faiSaveLoading"
								@click="faiDetailsDialogOpen = false"
							>
								{{ $i18n.t('general.close') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- End FAI license dialog -->
				<v-dialog
					v-model="faiEndDialogOpen"
					max-width="400"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title class="justify-center">
							{{ $i18n.t('fai.endButtonText') }}
						</v-card-title>
						<v-card-text class="text-center">
							<Spinner
								v-if="faiSaveLoading === true"
								:text="faiSaveLoadingText"
							/>
							<template v-else>
								{{ $i18n.t('fai.endMembershipInfoText') }}
							</template>
						</v-card-text>
						<v-card-actions class="justify-center">
							<v-btn
								text
								:disabled="faiSaveLoading"
								@click="faiEndDialogOpen = false"
							>
								{{ $i18n.t('general.cancel') }}
							</v-btn>
							<v-btn
								text
								color="error"
								:disabled="faiSaveLoading"
								@click="endFaiLicense"
							>
								{{ $i18n.t('fai.endButtonText') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Purchase FAI license dialog -->
				<v-dialog
					v-model="faiPurchaseDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('fai.purchaseButtonText') }}
						</v-card-title>
						<v-card-text>
							<Spinner
								v-if="faiSaveLoading === true"
								:text="faiSaveLoadingText"
							/>
							<!-- Check that user is able to acquire the new FAI license -->
							<v-alert
								v-else-if="user._fai_license_purchase_possibility && user._fai_license_purchase_possibility.enabled === false"
								type="error"
							>
								{{ user._fai_license_purchase_possibility.disabledReason }}
							</v-alert>
							<FilterableList
								v-else
								:items="[
									{
										icon: 'mdi-cube',
										subtitle: $i18n.t('general.product'),
										title: $i18n.t('fai.title'),
									},
									{
										icon: 'mdi-currency-eur',
										subtitle: $i18n.t('general.price'),
										title: user._fai_license_purchase_possibility ? user._fai_license_purchase_possibility.price_formatted : null,
									},
								]"
								title-src="title"
								summary-src="summary"
								icon-src="icon"
								:enable-click="false"
								:enable-search="false"
								:multiline="true"
							/>
						</v-card-text>
						<v-card-actions>
							<template v-if="user._fai_license_purchase_possibility && user._fai_license_purchase_possibility.enabled === true">
								<v-btn
									text
									:disabled="faiSaveLoading"
									@click="faiPurchaseDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									color="primary"
									text
									:disabled="faiSaveLoading"
									@click="createFaiLicense"
								>
									<v-icon left>
										mdi-cart
									</v-icon>
									{{ $i18n.t('general.purchase') }}
								</v-btn>
							</template>
							<template v-else>
								<v-spacer />
								<v-btn
									color="primary"
									text
									@click="faiPurchaseDialogOpen = false"
								>
									OK
								</v-btn>
							</template>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-card>
		</v-container>

		<!-- Athlete agreement -->
		<v-container
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('athleteAgreement.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						v-if="user.urheilijasopimus && user.urheilijasopimus.length"
						:items="user.urheilijasopimus"
						title-src="_list_title"
						summary-src="_list_summary"
						icon-src="_icon"
						:enable-search="false"
						:multiline="true"
						@itemClick="athleteAgreementItemClick"
					/>
					<v-alert
						v-else
						type="info"
						class="mb-0"
					>
						{{ $i18n.t('athleteAgreement.noAgreementText') }}
					</v-alert>
				</v-card-text>
				<template v-if="!user.urheilijasopimus || !user.urheilijasopimus.length">
					<v-divider />
					<v-card-actions>
						<v-btn
							color="primary"
							text
							@click="athleteAgreementAcquireDialogOpen = true"
						>
							<v-icon left>
								mdi-plus-circle
							</v-icon>
							{{ $i18n.t('athleteAgreement.acquire') }}
						</v-btn>
					</v-card-actions>
				</template>

				<!-- View a single atlete agreement -->
				<v-dialog
					v-model="athleteAgreementDetailsDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('athleteAgreement.title') }}
						</v-card-title>
						<v-card-text>
							<FilterableList
								v-if="athleteAgreementSelected"
								:items="athleteAgreementSelected._details"
								subtitle-src="label"
								title-src="value"
								:enable-search="false"
								:multiline="true"
								@itemClick="faiDetailsDialogOpen = true"
							/>

							<v-btn
								color="primary"
								class="mt-3"
								:href="config.athlete_agreement_terms_link"
								target="_blank"
								:disabled="athleteAgreementSaveLoading === true"
							>
								<v-icon left>
									mdi-open-in-new
								</v-icon>
								{{ $i18n.t('athleteAgreement.dialog.linkText') }}
							</v-btn>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								text
								@click="athleteAgreementDetailsDialogOpen = false"
							>
								OK
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Acquire athlete agreement dialog -->
				<v-dialog
					v-model="athleteAgreementAcquireDialogOpen"
					max-width="500"
					persistent
					scrollable
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('athleteAgreement.acquire') }}
						</v-card-title>
						<v-card-text>
							<!-- Check that user is able to acquire the agreement -->
							<v-alert
								v-if="user._latest_association_invoice_paid === false"
								type="error"
							>
								{{ $i18n.t('general.latestAssociationInvoiceNotValid') }}
							</v-alert>
							<template v-else>
								<template v-if="config.athlete_agreement_terms_link">
									{{ $i18n.t('athleteAgreement.dialog.infoText') }}
									<v-btn
										color="primary"
										class="my-6"
										:href="config.athlete_agreement_terms_link"
										target="_blank"
										:disabled="athleteAgreementSaveLoading === true"
									>
										<v-icon left>
											mdi-open-in-new
										</v-icon>
										{{ $i18n.t('athleteAgreement.dialog.linkText') }}
									</v-btn>
								</template>
								<SchemaToForm
									v-model="athleteAgreementFormData"
									:schema="config.forms.athleteAgreement"
									:disabled="athleteAgreementSaveLoading"
									:debug="false"
									@valid="athleteAgreementFormValid = true"
									@invalid="athleteAgreementFormValid = false"
								/>
							</template>
						</v-card-text>
						<v-card-actions>
							<template v-if="user._latest_association_invoice_paid === false">
								<v-spacer />
								<v-btn
									color="primary"
									text
									@click="athleteAgreementAcquireDialogOpen = false"
								>
									OK
								</v-btn>
							</template>
							<template v-else>
								<v-btn
									text
									:disabled="athleteAgreementSaveLoading"
									@click="athleteAgreementAcquireDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									color="primary"
									text
									:disabled="athleteAgreementSaveLoading || !athleteAgreementFormValid"
									:loading="athleteAgreementSaveLoading"
									@click="acquireAthleteAgreement"
								>
									{{ $i18n.t('general.save') }}
								</v-btn>
							</template>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	data: () => ({
		pdfDialogOpen: false,
		pdfDownloadLinks: [],
		renewLicenseDialogOpen: false,
		renewLicenseSaveLoading: false,
		renewLicenseLoadingText: null,
		renewLicenseFormData: {},
		renewLicenseFormValid: false,
		faiDetailsDialogOpen: false,
		faiPurchaseDialogOpen: false,
		faiEndDialogOpen: false,
		faiSaveLoading: false,
		faiSaveLoadingText: null,
		athleteAgreementAcquireDialogOpen: false,
		athleteAgreementFormData: {},
		athleteAgreementFormValid: false,
		athleteAgreementSaveLoading: false,
		athleteAgreementDetailsDialogOpen: false,
		athleteAgreementSelected: null,
	}),
	computed: {
		...mapState([
			'config',
			'user',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()

		// Build PDF download links
		this.pdfDownloadLinks = this.config.languages.reduce((acc, item) => {
			acc.push({
				icon: 'mdi-file-pdf-box',
				list_title: item.title,
				redirect_url: this.$api.baseUrl + '/' + item.code + '/me/pdf/',
			})

			return acc
		}, [])
	},
	methods: {
		// Renew parachute license
		renewLicense () {
			this.renewLicenseSaveLoading = true
			this.renewLicenseLoadingText = this.$i18n.t('licenses.renewLicenseLoadingText')

			this.$api.License.doRequest({
				url: 'renewparachutelicense',
				method: 'POST',
				body: this.renewLicenseFormData,
			}).on('done', (res) => {
				this.renewLicenseSaveLoading = false
				if (res.body.error) return

				this.renewLicenseDialogOpen = false
			}).on('fail', () => {
				this.renewLicenseSaveLoading = false
			})
		},

		// Create a new FAI license
		createFaiLicense () {
			this.faiSaveLoading = true
			this.faiSaveLoadingText = this.$i18n.t('fai.loadingSaveMembershipText')

			this.$api.Me.doRequest({
				url: 'fai/create',
				method: 'POST',
			}).on('done', (res) => {
				this.faiSaveLoading = false
				this.faiPurchaseDialogOpen = false

				// If invoice ID was returned, navigate to invoice page
				if (res.body && res.body.invoice_id) {
					this.$router.push({ name: 'invoice', params: { pagename: res.body.invoice_id } })
				}
			}).on('fail', () => {
				this.faiSaveLoading = false
			})
		},

		// End FAI License
		endFaiLicense () {
			this.faiSaveLoading = true
			this.faiSaveLoadingText = this.$i18n.t('fai.loadingEndMembershipText')

			this.$api.Me.doRequest({
				url: 'fai/end',
				method: 'POST',
			}).on('done', () => {
				this.faiDetailsDialogOpen = false
				this.faiEndDialogOpen = false
			}).on('finish', () => {
				this.faiSaveLoading = false
			})
		},

		// Select a single athlete agreement and open a dialog
		athleteAgreementItemClick (item) {
			this.athleteAgreementSelected = item
			this.athleteAgreementDetailsDialogOpen = true
		},

		// Acquire athlete agreement
		acquireAthleteAgreement () {
			this.athleteAgreementSaveLoading = true

			this.$api.Me.doRequest({
				url: 'athlete-agreement',
				method: 'POST',
				body: this.athleteAgreementFormData,
			}).on('done', (res) => {
				if (!res.body.error) {
					this.athleteAgreementAcquireDialogOpen = false
				}
			}).on('finish', () => {
				this.athleteAgreementSaveLoading = false
			})
		},
	},
}
</script>

<style lang="scss">

	.membershipCard {
		&__title,
		&__subtitle {
			padding-right: 80px;
		}

		&__title {
			padding-top: 45px;
		}

		&__logo {
			position: absolute;
			right: 16px;

			&--fai {
				top: 50px;
			}

			&--sil {
				top: 126px;
			}
		}

		&__validityText {
			position: absolute;
			top: 16px;
			right: 16px;
			color: #fff;
			font-size: 0.875rem;
			font-weight: bold;
		}
	}

	.membershipCard__suomenIlmailulisenssi {
		color: #fff;
		position: absolute;
		font-size: 0.875rem;
		top: 16px;
		left: 16px;
		font-weight: bold;
		text-shadow: 0 0 16px #000;
	}

	.membershipCardPrintable {
		display: none;
		position: relative;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-end;
		width: 85.6mm;
		height: 54.36mm;
		border-radius: 3mm;
		background-size: cover;
		color: #fff;

		@media print {
			display: flex;
			visibility: visible;

			* {
				position: relative;
				visibility: visible;
			}
		}

		&__title {
			font-size: 14pt !important;
			font-weight: 400;
			line-height: 2rem;
		}

		&__subtitle {
			font-size: 8pt !important;
			line-height: 1.4 !important;
		}

		&__logo {
			position: absolute;
			width: 8mm;
			height: auto;
			right: 5mm;

			&--fai {
				top: 12mm;
			}

			&--sil {
				top: 27mm;
			}
		}

		&__validityText {
			position: absolute;
			top: 5mm;
			right: 5mm;
			color: #fff;
			font-size: 8pt;
			font-weight: bold;
		}
	}

</style>
