<i18n>
{
  "en": {
		"buttons": {
			"printLicense": "Print"
		},
		"ratings": {
			"title": "Ratings",
			"noItemsText": "License has no ratings"
		},
		"licenseRenewDialog": {
			"title": "Renew license",
			"loadingText": "Renewing license"
		}
	},
  "fi": {
		"buttons": {
			"printLicense": "Tulosta"
		},
		"ratings": {
			"title": "Kelpoisuudet",
			"noItemsText": "Lisenssillä ei ole kelpoisuuksia"
		},
		"licenseRenewDialog": {
			"title": "Uudista lisenssi",
			"loadingText": "Uudistetaan lisenssiä"
		}
	}
}
</i18n>

<template>
	<div v-if="item">
		<v-container class="container--narrow">
			<v-card>
				<v-card-text
					v-if="item.activity === true"
					class="pa-0"
				>
					<LicenseCard
						:user="user"
						:license="item"
					/>
				</v-card-text>
				<v-card-text>
					<FilterableList
						:items="item._details"
						:multiline="true"
						:enable-search="false"
						:enable-click="false"
						subtitle-src="label"
						title-src="value"
					/>
				</v-card-text>
				<template v-if="item.activity === true || item._is_renewable.enabled === true">
					<v-divider />
					<v-card-actions>
						<v-btn
							v-if="item.activity === true"
							color="primary"
							text
							@click="printLicense"
						>
							<v-icon left>
								mdi-printer
							</v-icon>
							{{ $i18n.t('buttons.printLicense') }}
						</v-btn>
						<!-- <v-btn
							v-if="item._is_renewable.enabled === true"
							color="primary"
							text
							@click="openLicenseRenewDialog"
						>
							<v-icon left>
								mdi-star
							</v-icon>
							{{ $i18n.t('licenseRenewDialog.title') }}
						</v-btn> -->

						<!-- Renew license dialog -->
						<!-- <v-dialog
							v-model="licenseRenewDialogOpen"
							max-width="400"
							persistent
							scrollable
						>
							<v-card>
								<v-card-title>
									{{ $i18n.t('licenseRenewDialog.title') }}
								</v-card-title>
								<v-card-text>
									<Spinner
										v-if="licenseRenewDialogLoading === true"
										:text="licenseRenewDialogLoadingText"
									/>
									<SchemaToForm
										v-else
										ref="licenseRenewForm"
										v-model="licenseRenewFormData"
										:schema="config.forms.licenseRenew"
										:disabled="licenseRenewDialogLoading"
										:debug="(false)"
										@valid="licenseRenewFormValid = true"
										@invalid="licenseRenewFormValid = false"
									/>
								</v-card-text>
								<v-card-actions>
									<v-btn
										text
										:disabled="licenseRenewDialogLoading === true"
										@click="licenseRenewDialogOpen = false"
									>
										{{ $i18n.t('general.cancel') }}
									</v-btn>
									<v-spacer />
									<v-btn
										text
										color="primary"
										:disabled="licenseRenewFormValid === false || licenseRenewDialogLoading === true"
										@click="renewLicense"
									>
										{{ $i18n.t('licenseRenewDialog.title') }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog> -->
					</v-card-actions>
				</template>
			</v-card>
		</v-container>
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('ratings.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						v-if="item.c_kelpoisuudet.length"
						:items="item.c_kelpoisuudet"
						:multiline="true"
						:enable-search="false"
						:enable-click="false"
						title-src="_c_kelpuutukset_luokka_title"
						summary-src="_date_range_formatted"
						icon-src="_icon"
					/>
					<v-alert
						v-else
						type="info"
						class="mb-0"
					>
						{{ $i18n.t('ratings.noItemsText') }}
					</v-alert>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import LicenseCard from '@/components/LicenseCard'

export default {
	name: 'License',
	components: {
		LicenseCard,
	},
	data: () => ({
		item: null,
		licenseRenewFormValid: false,
		licenseRenewFormData: {},
		licenseRenewDialogOpen: false,
		licenseRenewDialogLoading: false,
		licenseRenewDialogLoadingText: null,
	}),
	computed: {
		...mapState({
			user: state => state.user,
			config: state => state.config,
			items: state => state.user.lisenssit,
		}),
	},
	mounted () {
		this.loadItem()
	},
	methods: {
		// Find current page from the store
		loadItem () {
			this.item = this.items.find(item => {
				return item.id == this.$route.params.pagename
			})

			if (!this.item) this.$router.replace({ name: 'error404' })
		},

		printLicense () {
			window.print()
		},

		openLicenseRenewDialog () {
			this.licenseRenewFormData = {}

			// Open dialog
			this.licenseRenewDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.licenseRenewForm.$children[0].resetValidation()
			})
		},

		renewLicense () {
			this.licenseRenewDialogLoading = true
			this.licenseRenewDialogLoadingText = this.$i18n.t('licenseRenewDialog.loadingText')

			this.$api.License.doRequest({
				url: 'renew',
				method: 'POST',
				body: {
					license_id: this.item.id,
					...this.licenseRenewFormData,
				},
			}).on('done', (res) => {
				this.licenseRenewDialogOpen = false

				// If license ID was returned, navigate to license page
				if (res.body && res.body.newLicenseId) {
					this.$router.push({ name: 'license', params: { pagename: res.body.newLicenseId } })

					this.$nextTick(() => {
						this.loadItem()
					})
				}
			}).on('finish', () => {
				this.licenseRenewDialogLoading = false
			})
		},
	},
}
</script>

<style lang="scss">

</style>
