<i18n>
{
	"en": {
		"noItemsText": "You have no valid licenses."
	},
	"fi": {
		"noItemsText": "Sinulla ei ole voimassa olevia lisenssejä."
	}
}
</i18n>

<template>
	<div>
		<FilterableList
			v-if="items && items.length"
			:items="items"
			title-src="_list_title"
			summary-src="_list_summary"
			icon-src="_icon"
			icon-color-src="_icon_color"
			:enable-search="false"
			:multiline="true"
			@itemClick="item => $router.push({ name: 'license', params: { pagename: item.id } })"
		>
			<template #meta="slotProps">
				<v-chip
					v-if="slotProps.item._status && Object.keys(slotProps.item._status).length"
					:color="slotProps.item._status.color"
					outlined
					label
				>
					<v-icon
						v-if="slotProps.item._status.icon"
						left
					>
						{{ slotProps.item._status.icon }}
					</v-icon>

					{{ slotProps.item._status.text }}
				</v-chip>
			</template>
		</FilterableList>
		<v-alert
			v-else
			type="info"
			class="mb-0"
		>
			{{ $i18n.t('noItemsText') }}
		</v-alert>
	</div>
</template>

<script>

export default {
	name: 'LicenseList',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
	},
}

</script>
