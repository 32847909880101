import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Error404 from '@/views/Error404.vue'
import MembershipCard from '@/views/MembershipCard.vue'
import MyAccount from '@/views/MyAccount.vue'
import Settings from '@/views/Settings.vue'
import BenefitContainer from '@/views/BenefitContainer.vue'
import Benefit from '@/views/Benefit.vue'
// import EventContainer from '@/views/EventContainer.vue'
// import Event from '@/views/Event.vue'
import NewsContainer from '@/views/NewsContainer.vue'
import News from '@/views/News.vue'
// import PageContainer from '@/views/PageContainer.vue'
// import Page from '@/views/Page.vue'
import MagazineContainer from '@/views/MagazineContainer.vue'
import LicenseContainer from '@/views/LicenseContainer.vue'
import License from '@/views/License.vue'
import InvoiceContainer from '@/views/InvoiceContainer.vue'
import Invoice from '@/views/Invoice.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'membershipCard',
		component: MembershipCard,
		meta: {
			icon: 'mdi-badge-account-horizontal',
		},
	},
	{
		path: '/myaccount',
		name: 'myAccount',
		component: MyAccount,
		meta: {
			icon: 'mdi-badge-account-horizontal',
			hidden: true,
		},
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		meta: {
			icon: 'mdi-cog',
			hidden: true,
		},
	},
	{
		path: '/benefits',
		name: 'benefitContainer',
		component: BenefitContainer,
		meta: {
			icon: 'mdi-star',
		},
		children: [
			{
				path: ':pagename',
				name: 'benefit',
				component: Benefit,
				props: true,
			},
		],
	},
	// {
	// 	path: "/events",
	// 	name: "eventContainer",
	// 	component: EventContainer,
	// 	meta: {
	// 		icon: "mdi-calendar"
	// 	},
	// 	children: [
	// 		{
	// 			path: ":pagename",
	// 			name: "event",
	// 			component: Event,
	// 			props: true,
	// 		}
	// 	]
	// },
	{
		path: '/news',
		name: 'newsContainer',
		component: NewsContainer,
		meta: {
			hidden: true,
			icon: 'mdi-bullhorn',
		},
		children: [
			{
				path: ':pagename',
				name: 'news',
				component: News,
				props: true,
			},
		],
	},
	{
		path: '/magazines',
		name: 'magazineContainer',
		component: MagazineContainer,
		meta: {
			icon: 'mdi-newspaper-variant',
		},
	},
	// {
	// 	path: "/pages",
	// 	name: "pageContainer",
	// 	component: PageContainer,
	// 	meta: {
	// 		icon: "mdi-file-document-multiple"
	// 	},
	// 	children: [
	// 		{
	// 			path: ":pagename",
	// 			name: "page",
	// 			component: Page,
	// 			props: true,
	// 		}
	// 	]
	// },
	{
		path: '/licenses',
		name: 'licenseContainer',
		component: LicenseContainer,
		meta: {
			icon: 'mdi-license',
			hidden: true,
		},
		children: [
			{
				path: ':pagename',
				name: 'license',
				component: License,
				props: true,
			},
		],
	},
	{
		path: '/invoices',
		name: 'invoiceContainer',
		component: InvoiceContainer,
		meta: {
			icon: 'mdi-receipt',
		},
		children: [
			{
				path: ':pagename',
				name: 'invoice',
				component: Invoice,
				props: true,
			},
		],
	},
	{
		name: 'error404',
		path: '/error404',
		component: Error404,
		meta: {
			hidden: true,
		},
	},
	{
		path: '*',
		redirect: {
			name: 'error404',
		},
		meta: {
			hidden: true,
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// Navigation guards
router.beforeEach((to, from, next) => {
	// If current route is not the first one the user visits,
	// we switch back button to the history mode.
	if (from.name !== null) {
		store.commit('setBackButtonHistoryMode', true)
	}

	// If navigation confirmation is requested, show dialog
	if (store.state.confirmNavigation) {
		store.commit('setConfirmNavigationDialog', true)
		store.commit('setConfirmNavigationRoute', to)
	} else {
		next()
	}
})

export default router
