<i18n>
{
  "en": {
		"noItemsMessage": "No benefits to show."
	},
  "fi": {
		"noItemsMessage": "Jäsenetuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'benefitContainer'">
			<v-container
				class="container--narrow"
			>
				<FilterableList
					v-if="items.length"
					:items="items"
					@itemClick="itemClick"
				/>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsMessage') }}
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',
	computed: {
		...mapState({
			items: state => state.benefits,
		}),
	},
	mounted () {
		this.$api.Benefits.doRequest()
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'benefit', params: { pagename: item.name } })
		},
	},
}
</script>
