<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="container--narrow pt-6">
			<p
				v-if="item.summary"
				class="font-weight-medium"
			>
				{{ item.summary }}
			</p>
			<ContentBlocks
				:items="item.content_blocks"
			/>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'News',
	data: () => ({
		item: null,
	}),
	computed: {
		...mapState([
			'benefits',
		]),
	},
	mounted () {
		// Find current item from the store
		this.item = this.benefits.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'error404' })
		}

		// Set custom headline
		this.$nextTick(() => {
			this.$root.$emit('setHeadline', this.item.title)
		})
	},
}
</script>
