<i18n>
{
	"en": {
		"tabs": {
			"basicDetails": "Basic details",
			"hobbies": "Hobbies"
		},
		"hobby": {
			"noItemsMessage": "No hobbies to show",
			"addNew": "Add new hobby",
			"editExisting": "Edit hobby",
			"end": {
				"title": "End this hobby",
				"confirmText": "Are you sure you want to end this hobby?"
			}
		}
	},
	"fi": {
		"tabs": {
			"basicDetails": "Omat tiedot",
			"hobbies": "Harrasteet"
		},
		"hobby": {
			"noItemsMessage": "Ei harrasteita",
			"addNew": "Lisää uusi harraste",
			"editExisting": "Muokkaa harrastetta",
			"end": {
				"title": "Päätä harraste",
				"confirmText": "Haluatko varmasti päättää tämän harrasteen?"
			}
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="ready === false"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-else>
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-tabs
					v-model="currentTab"
					centered
					center-active
					show-arrows
				>
					<v-tabs-slider />
					<v-tab
						v-for="(item, index) in tabs"
						:key="'tab-' + index"
					>
						{{ $i18n.t('tabs.' + item) }}
					</v-tab>
				</v-tabs>
			</v-toolbar>
			<v-tabs-items v-model="currentTab">
				<!-- Basic details -->
				<v-tab-item>
					<v-container class="container--narrow">
						<v-card v-if="config.forms && config.forms.myAccount">
							<v-card-text>
								<SchemaToForm
									v-model="formData"
									:schema="config.forms.myAccount"
									:disabled="saveLoading"
									:debug="false"
									@valid="formValid = true"
									@invalid="formValid = false"
									@change="$store.commit('setConfirmNavigation', true)"
								/>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-spacer />
								<v-btn
									color="primary"
									:loading="saveLoading"
									:disabled="saveLoading || !formValid"
									@click="save"
									text
								>
									{{ $i18n.t('general.save') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-container>
				</v-tab-item>

				<!-- Hobbies -->
				<v-tab-item>
					<v-container class="container--narrow">
						<FilterableList
							v-if="user.harrasteet.length"
							:items="user.harrasteet"
							title-src="_categoryTitle"
							summary-src="_date_range_formatted"
							icon-src="_icon"
							:enable-search="(false)"
							@itemClick="openHobbyDialog"
						/>
						<v-alert
							v-else
							type="info"
						>
							{{ $i18n.t('hobby.noItemsMessage') }}
						</v-alert>
						<v-btn
							color="primary"
							class="ma-3"
							@click="openHobbyDialog(null)"
							:disabled="hobbySaveLoading"
						>
							<v-icon
								left
							>
								mdi-plus
							</v-icon>
							{{ $i18n.t('hobby.addNew') }}
						</v-btn>
					</v-container>

					<!-- Add hobby dialog -->
					<v-dialog
						v-model="hobbyDialogOpen"
						max-width="500"
						persistent
						scrollable
					>
						<v-card>
							<v-card-title>
								<template v-if="hobbyFormData.id">
									{{ $i18n.t('hobby.editExisting') }}
								</template>
								<template v-else>
									{{ $i18n.t('hobby.addNew') }}
								</template>
							</v-card-title>
							<v-card-text>
								<SchemaToForm
									v-if="hobbyDialogOpen"
									ref="hobbyForm"
									v-model="hobbyFormData"
									:schema="config.forms.hobby"
									:disabled="hobbyFormData.id != null || hobbySaveLoading"
									:debug="(false)"
									@valid="hobbyFormValid = true"
									@invalid="hobbyFormValid = false"
								/>
								<v-btn
									v-if="hobbyFormData.id"
									color="error"
									class="mt-6"
									:disabled="hobbySaveLoading"
									@click="hobbyEndConfirmDialogOpen = true"
								>
									<v-icon
										left
									>
										mdi-calendar-remove
									</v-icon>
									{{ $i18n.t('hobby.end.title') }}
								</v-btn>

								<!-- End hobby confirmation dialog -->
								<v-dialog
									v-model="hobbyEndConfirmDialogOpen"
									max-width="300"
									persistent
									scrollable
								>
									<v-card>
										<v-card-title class="justify-center">
											{{ $i18n.t('hobby.end.title') }}
										</v-card-title>
										<v-card-text class="text-center">
											{{ $i18n.t('hobby.end.confirmText') }}
										</v-card-text>
										<v-card-actions class="justify-center">
											<v-btn
												text
												:disabled="hobbySaveLoading"
												@click="hobbyEndConfirmDialogOpen = false"
											>
												{{ $i18n.t('general.cancel') }}
											</v-btn>
											<v-btn
												color="error"
												text
												:disabled="hobbySaveLoading"
												:loading="hobbySaveLoading"
												@click="endHobby"
											>
												{{ $i18n.t('general.yes') }}
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</v-card-text>
							<v-card-actions>
								<template v-if="hobbyFormData.id">
									<v-spacer />
									<v-btn
										color="primary"
										text
										@click="hobbyDialogOpen = false"
									>
										{{ $i18n.t('general.close') }}
									</v-btn>
								</template>
								<template v-else>
									<v-btn
										text
										:disabled="hobbySaveLoading"
										@click="hobbyDialogOpen = false"
									>
										{{ $i18n.t('general.cancel') }}
									</v-btn>
									<v-spacer />
									<v-btn
										color="primary"
										text
										:disabled="hobbySaveLoading || !hobbyFormValid"
										:loading="hobbySaveLoading"
										@click="saveHobby"
									>
										{{ $i18n.t('general.save') }}
									</v-btn>
								</template>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',
	data: () => ({
		ready: false,
		tabs: [
			'basicDetails',
			'hobbies',
		],
		currentTab: 0,
		formData: {},
		saveLoading: false,
		formValid: false,
		hobbyDialogOpen: false,
		hobbyFormData: {},
		hobbyFormValid: false,
		hobbySaveLoading: false,
		hobbyEndConfirmDialogOpen: false,
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	mounted () {
		this.resetHobbyForm()

		this.$api.Me.doRequest().then(() => {
			this.formData = JSON.parse(JSON.stringify(this.user))
			this.ready = true
		})
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.memberData) {
					this.formData = res.body.memberData
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},

		resetHobbyForm () {
			this.hobbyFormData = {
				startdate: new Date().toISOString().substring(0, 10),
			}
		},

		openHobbyDialog (item) {
			this.resetHobbyForm()

			// If we are editing existing item, set form data
			if (item && item.id) {
				this.hobbyFormData = Object.assign({}, item)
			}

			// Open dialog
			this.hobbyDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.hobbyForm.$children[0].resetValidation()
			})
		},

		saveHobby () {
			this.hobbySaveLoading = true

			this.$api.Me.doRequest({
				url: 'hobby',
				method: 'POST',
				body: this.hobbyFormData,
			}).on('done', res => {
				if (!res.body.error) {
					this.hobbyDialogOpen = false
				}
			}).on('finish', () => {
				this.hobbySaveLoading = false
			})
		},

		endHobby () {
			this.hobbySaveLoading = true

			this.$api.Me.doRequest({
				url: 'hobby/end',
				method: 'POST',
				body: this.hobbyFormData,
			}).on('done', res => {
				if (!res.body.error) {
					this.hobbyEndConfirmDialogOpen = false
					this.hobbyDialogOpen = false
				}
			}).on('finish', () => {
				this.hobbySaveLoading = false
			})
		},
	},
}
</script>
